// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { CartProvider } from './context/cartContext';
import { AuthProvider } from './context/AuthContext';
import ManagerChoice from './components/ManagerChoice';
import ManagerScan from './components/ManagerScan';
import ScanSuccessTopUp from './components/ScanSuccessTopUp';
import ScanSuccessPayment from './components/ScanSuccessPayment';
import ErrorBalance from './components/ErrorBalance';
import ErrorBalanceClient from './components/ErrorBalanceClient';
import ClientView from './components/ClientView';
import QRCodeView from './components/QRCodeView';
import Login from './components/Login';
import QRTest from './components/QRTest';
import Signup from './components/Signup';
import VerifyEmail from './components/verifyEmail';
import ValidateEmailURL from './components/validateEmailURL';
import ManSuccessPayment from './components/ManSuccessPayment';
import ManSuccessTopUp from './components/ManSuccessTopUp';
import LandingPage from './components/LandingPage';
import PasswordReset from './components/PasswordReset';
import Action from './components/Action';
import ProductsList from './components/ProductsList'; 
import Cart from './components/Cart';
import ProductsOrder from './components/ProductsOrder';
import StockAndProfit from './components/StockAndProfit';
import EventInfos from './components/EventsPage';
import PasswordRequest from './components/PasswordResetRequest';
import RewardsList from './components/rewardsList';
import RewardQRCodeView from './components/rewardQRCodeView';
import Checkout from './components/Checkout';
import OrdersList from './components/OrdersList';
import ReceiptList from './components/ReceiptList';
import ProtectedRoute from './components/ProtectedRoute';
import SalesSearch from './components/SalesSearch';
import ProductsSwitches from './components/ProductsSwitches';

function App() {
  return (
    <AuthProvider>
      <CartProvider>
        <Router>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/validate-email" element={<ValidateEmailURL />} />
            <Route path="/verify-email" element={<VerifyEmail />} />
            <Route path="/action" element={<Action />} />
            <Route path="/qrtest" element={<QRTest />} />
            <Route path="/manager" element={
              <ProtectedRoute role="manager">
                <ManagerChoice />
              </ProtectedRoute>
            } />
            <Route path="/products" element={<ProductsList />} /> 
            <Route path="/cart" element={<Cart />} />
            <Route path="/password-reset" element={<PasswordReset />} />
            <Route path="/manager/scan/:action/:amount" element={
              <ProtectedRoute role="manager">
                <ManagerScan />
              </ProtectedRoute>
            } />
            <Route path="/success-topup/:amount/:userHash" element={<ScanSuccessTopUp />} />
            <Route path="/success-payment/:amount/:transactionId/:userHash" element={<ScanSuccessPayment />} />
            <Route path="/man-payment/:amount/:userHash" element={
              <ProtectedRoute role="manager">
                <ManSuccessPayment />
              </ProtectedRoute>
            } />
            <Route path="/man-topup/:amount/:userHash" element={
              <ProtectedRoute role="manager">
                <ManSuccessTopUp />
              </ProtectedRoute>
            } />
            <Route path="/error-balance" element={<ErrorBalance />} />
            <Route path="/error-balance-client" element={<ErrorBalanceClient />} />
            <Route path="/qrcode" element={<QRCodeView />} />
            <Route path="/drinks" element={<ProductsOrder />} />
            <Route path="/stock-profit" element={<StockAndProfit />} />
            <Route path="/events" element={<EventInfos />} />
            <Route path="/password-request" element={<PasswordRequest />} />
            <Route path="/rewards-list" element={<RewardsList />} />
            <Route path="/rewards-qr/:rewardName" element={<RewardQRCodeView />} />
            <Route path="/orders" element={<Checkout />} />
            <Route path="/orders-list" element={<OrdersList />} />
            <Route path="/receipts" element={<ReceiptList />} />
            <Route path="/search" element={<SalesSearch />} />
            <Route path="/stockmanagement" element={<ProductsSwitches />} />
          </Routes>
        </Router>
      </CartProvider>
    </AuthProvider>
  );
}

export default App;




