import React from 'react';
import '../styles/ReceiptCard.css';

const ReceiptCard = ({ orderDetails, pendingBefore }) => {
  const orderStatus = orderDetails[0].status;
  console.log('ReceiptCard rendering with status:', orderStatus);

  const totalGraduations = 10;
  const filledGraduations =
    pendingBefore >= totalGraduations
      ? 0
      : pendingBefore <= 0
      ? totalGraduations
      : totalGraduations - pendingBefore;

  // Définition du titre et de l'icône selon pendingBefore
  const titleText =
    pendingBefore === 0
      ? "Go to the bar!"
      : `Sip, Sip, hooray! Just ${pendingBefore} orders ahead of you!`;
  const titleIcon = pendingBefore === 0 ? "🏃🏽‍♂️" : "⏳";

  return (
    <div className={`orderContainer ${orderStatus === 'processing' ? 'processingOrder' : ''}`}>
      <div className="orderCard">
        <div className="orderHeader">
          <h5>ID: {orderDetails[0].order_id}</h5>
        </div>
        {orderStatus === 'pending' && (
          <>
            <div className="orderTitle">
              <span className="timeIcon">{titleIcon}</span>
              <span>{titleText}</span>
            </div>
            <div className="progressBarGraduated">
              {Array.from({ length: totalGraduations }).map((_, i) => (
                <div
                  key={i}
                  className={`graduation ${i < filledGraduations ? 'filled' : ''}`}
                ></div>
              ))}
            </div>
          </>
        )}
        <div className="orderBody">
          {orderDetails.map(item => (
            <div key={item.productName} className="orderItem">
              <span>{item.productName}</span>
              <span>{item.quantity}</span>
            </div>
          ))}
        </div>
        <div className="orderFooter">
          <span>{orderStatus}</span>
        </div>
      </div>
    </div>
  );
};

export default ReceiptCard;




