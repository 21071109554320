// src/components/ProductsSwitches.js

import React, { useState, useEffect } from 'react';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import '../styles/ProductsSwitches.css'; 
import MainNavigationManager from './MainNavigationManager';

const ProductsSwitches = () => {
  const [products, setProducts] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;

  // Récupérer la liste des produits au montage du composant
  useEffect(() => {
    fetch(`${apiUrl}/api/products`)
      .then(response => response.json())
      .then(data => setProducts(data))
      .catch(error => console.error('Erreur lors de la récupération des produits:', error));
  }, [apiUrl]);

  // Gérer le changement de position du switch avec une mise à jour locale optimiste
  const handleToggle = (productName, isEnabled) => {
    // Mettre à jour localement l'état du produit
    setProducts(prevProducts =>
      prevProducts.map(product =>
        product.productName === productName
          ? { ...product, disabled: !isEnabled }  // Si isEnabled est true, alors disabled devient false
          : product
      )
    );

    // Déterminer l'endpoint selon le nouvel état du switch
    const endpoint = isEnabled ? '/api/products/enable' : '/api/products/disable';
    fetch(`${apiUrl}${endpoint}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ productName })
    })
      .then(response => response.json())
      .then(data =>
        console.log(`Le produit ${productName} est ${isEnabled ? 'activé' : 'désactivé'}`, data)
      )
      .catch(error =>
        console.error(`Erreur lors de la mise à jour du produit ${productName}:`, error)
      );
  };

    return (
     <div>    
      <MainNavigationManager />
       <div className="stock-management-container">
        <h1 className="title">Products list</h1>
        {products.map(product => (
          <div className="stock-management-row" key={product.id}>
            <span className="product-name">{product.productName}</span>
            <FormControlLabel
              control={
                <Switch
                  checked={!product.disabled}
                  onChange={(e) => handleToggle(product.productName, e.target.checked)}
                />
              }
              label=""
            />
          </div>
        ))}
      </div>
     </div>
    );
};

export default ProductsSwitches;

