import React, { useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { useNavigate } from 'react-router-dom';
import { signOut } from "firebase/auth";
import { auth } from '../firebaseConfig'; // Mettez à jour avec le chemin correct



//drawer elements used
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import LocalBarIcon from '@mui/icons-material/LocalBar';
import EventIcon from '@mui/icons-material/Event';
import DiscountIcon from '@mui/icons-material/Discount';
import LogoutIcon from '@mui/icons-material/Logout';
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import ReceiptIcon from '@mui/icons-material/Receipt';


const StyledSearch = styled('div')(
  ({theme}) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.primary.main, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  })  
);

const SearchIconWrapper = styled('div')(
  ({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(
  ({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

export default function MainNavigation() {

  /*
  react useState hook to save the current open/close state of the drawer,
  normally variables dissapear afte the function was executed
  */
  const [open, setState] = useState(false);

  const navigate = useNavigate();
  /*
  function that is being called every time the drawer should open or close,
  the keys tab and shift are excluded so the user can focus between
  the elements with the keys
  */
  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    //changes the function state according to the value of open
    setState(open);
  };
    
  const handleLogout = async () => {
    try {
        await signOut(auth); // Déconnecter l'utilisateur via Firebase
        localStorage.clear();
        console.log("User Signed Out Successfully!");
        navigate('/login'); // Rediriger l'utilisateur vers la page de connexion
    } catch (error) {
        console.error('Error signing out:', error);
    }
  };       

  return (

    <AppBar position="static" sx={{ bgcolor: '#343541' }}>
      <Container maxWidth="lg" disableGutters="true">
        <Toolbar>

            <Typography 
              variant="h6" 
              sx={{
                flexGrow: 1,
                fontWeight: "bold",
                textAlign: "left", // Aligner le texte à gauche
                fontSize: "2rem", // Augmenter la taille de la police, ajustez selon vos besoins
              }}
            >
              CargoPero
            </Typography>

            <Box component="div" sx={{
              display: {
                xs: 'none',
                sm: 'block',
              }
            }}>
            </Box>

            <IconButton 
              edge="start" 
              color="inherit" 
              aria-label="open drawer" 
              onClick={toggleDrawer(true)}
              sx={{ 
                mr: 2,
                display: {
                  xs: 'block',
                }
              }}
            >
              <MenuIcon sx={{ fontSize: 40 }} /> 
            </IconButton>

            {/* The outside of the drawer */}
            <Drawer
              //from which side the drawer slides in
              anchor="right"
              //if open is true --> drawer is shown
              open={open}
              //function that is called when the drawer should close
              onClose={toggleDrawer(false)}
              //function that is called when the drawer should open
              onOpen={toggleDrawer(true)}
            >
                {/* The inside of the drawer */}
                <Box sx={{
                  p: 2,
                  height: 1,
                  backgroundColor: "#FAF9F6",
                }}>

                  {/* 
                  when clicking the icon it calls the function toggleDrawer 
                  and closes the drawer by setting the variable open to false
                  */}
                  <IconButton sx={{mb: 2}}>
                    <CloseIcon onClick={toggleDrawer(false)} />
                  </IconButton>

                  <Divider sx={{mb: 2}} />

                  <Box sx={{mb: 2}}>
                    <ListItemButton onClick={() => navigate('/drinks')}>
                      <ListItemIcon>
                        <LocalBarIcon sx={{color: "#2563eb"}}/>
                      </ListItemIcon>
                      <ListItemText primary="DRINKS MENU" />
                    </ListItemButton>

                    <ListItemButton onClick={() => navigate('/qrcode')}>
                      <ListItemIcon>
                        <AccountBalanceWalletIcon sx={{color: "#2563eb"}}/>
                      </ListItemIcon >
                      <ListItemText primary="WALLET" />
                    </ListItemButton>

                    <ListItemButton onClick={() => navigate('/receipts')}>
                      <ListItemIcon>
                        <ReceiptIcon sx={{color: "#2563eb"}}/>
                      </ListItemIcon>
                      <ListItemText primary="MY ORDERS" />
                    </ListItemButton>

                    <ListItemButton onClick={() => navigate('/events')}>
                      <ListItemIcon>
                        <EventIcon sx={{color: "#2563eb"}} />
                      </ListItemIcon>
                      <ListItemText primary="EVENTS" />
                    </ListItemButton>

                    <ListItemButton onClick={() => navigate('/rewards-list')}>
                      <ListItemIcon>
                        <DiscountIcon sx={{color: "#2563eb"}} />
                      </ListItemIcon>
                      <ListItemText primary="REWARDS" />
                    </ListItemButton>

                    <ListItemButton onClick={handleLogout}>
                      <ListItemIcon>
                        <LogoutIcon sx={{color: "#2563eb"}} />
                      </ListItemIcon>
                      <ListItemText primary="LOGOUT" />
                    </ListItemButton>            
                  </Box>
                </Box>
            </Drawer>
           

          </Toolbar>
      </Container>
    </AppBar>

  );
}