// src/components/ProductsList.js (ProductsOrder.js)

import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ProductsCard from './ProductsCard';
import { CartContext } from '../context/cartContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping } from '@fortawesome/free-solid-svg-icons';
import '../styles/ProductsList.css';
import io from 'socket.io-client';

const ProductsOrder = () => {
    const navigate = useNavigate();
    const { cartItems, clearCart } = useContext(CartContext);
    const totalItems = cartItems.reduce((total, item) => total + item.quantity, 0);
    const apiUrl = process.env.REACT_APP_API_URL;
    
    const [productsData, setProductsData] = useState([]);
    // Mapping qui associe chaque produit (identifié ici par productName) à l'état du bouton (true = désactivé)
    const [buttonsState, setButtonsState] = useState({});

    // Établir la connexion socket et écouter l'événement de mise à jour de l'état du bouton
    useEffect(() => {
      const socket = io(apiUrl);
      socket.on('buttonStateUpdate', (data) => {
         // data attendue : { productName: string, disabled: boolean }
         setButtonsState(prevState => ({ ...prevState, [data.productName]: data.disabled }));
      });
      return () => {
         socket.disconnect();
      };
    }, [apiUrl]);

    // Réinitialiser le panier lors du montage du composant
    useEffect(() => {
      clearCart();
    }, []);
    
    // Chargement des données produits depuis le serveur
    useEffect(() => {
        fetch(`${apiUrl}/api/products`)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('La requête a échoué.');
            })
            .then(data => setProductsData(data))
            .catch(error => console.error('Erreur lors de la récupération des produits:', error));
    }, [apiUrl]);

    return (
      <div>
        <header className="list-header">
            <h1 onClick={() => navigate('/')} style={{cursor: 'pointer'}}>CargoPero</h1>
            <button className="cart-button" onClick={() => navigate('/orders')}>
                <FontAwesomeIcon icon={faCartShopping} style={{ color: "#ffffff", fontSize: '3em' }} />
                {cartItems.length > 0 && (
                    <span className="cart-item-count">{totalItems}</span>
                )}
            </button>
        </header>
        <div className="listContainer">
            <h2>DRINKS</h2>
            {productsData.map((product) => {
              const isDisabled = buttonsState.hasOwnProperty(product.productName)
                ? buttonsState[product.productName]
                : product.disabled || false;
              return (
               <ProductsCard 
                 key={product.id} 
                 {...product} 
                 disabled={isDisabled} 
               />
             );   
          })}
        </div>
      </div>
    );
};

export default ProductsOrder;

