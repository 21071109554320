// src/components/ProductsCard.js

import React, { useContext, useState } from 'react';
import { CartContext } from '../context/cartContext';
import '../styles/ProductsCard.css';

const ProductsCard = (props) => {
    const { img, rating, productName, price, disabled } = props;
    const { addItem } = useContext(CartContext);
    const [isAdded, setIsAdded] = useState(false);

    const handleAddToCart = () => {
        if (disabled) return; // Empêche l'action si le bouton est désactivé

        // Ajouter l'article au panier
        const item = { ...props, quantity: 1 };
        console.log('Dispatching ADD_TO_CART with item:', item);
        addItem(item);

        // Feedback visuel indiquant que l'article a été ajouté
        setIsAdded(true);
        setTimeout(() => {
            setIsAdded(false);
        }, 1000); // L'état revient à la normale après 1 seconde
    };

    return (
          <div className="productContainer">
            <div className="productCard">
                <div className="productImage">
                    <img src={img} alt={productName} />
                </div>
                <div className="productInfo">
                    <h4 className="productName">{productName}</h4>
                    <h3 className="price">MMK {price.toLocaleString()}</h3>
                    <button
                        type="button"
                        className={`btn ${isAdded ? 'added' : ''} ${disabled ? 'disabled' : ''}`}
                        onClick={handleAddToCart}
                        disabled={disabled}
                    >
                        {disabled ? 'Sold out 😞' : (isAdded ? 'Added' : 'Add to cart')}
                    </button>
                </div>
            </div>
          </div>
    );
};

export default ProductsCard;

