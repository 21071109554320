import React, { useState, useEffect } from 'react';
import ReceiptCard from './ReceiptCard';
import MainNavigation from './MainNavigation';
import '../styles/OrdersList.css';
import io from 'socket.io-client';

const ReceiptList = () => {
  console.log('ReceiptList component rendered');
  const [orders, setOrders] = useState([]);
  const [showHistory, setShowHistory] = useState(false);

  // Récupération initiale des commandes du client
  useEffect(() => {
    const API_URL = process.env.REACT_APP_API_URL;
    const userHash = localStorage.getItem('user_hash');

    const fetchOrders = () => {
      fetch(`${API_URL}/api/orders/user/${userHash}`)
        .then(response => response.json())
        .then(data => {
          console.log('Orders fetched for user:', data);
          const ordersArray = Object.entries(data)
            .map(([key, value]) => ({
              orderId: key,
              orderDetails: value, // { orders, pendingBefore, createdAt }
            }))
            .filter(order => order.orderDetails.orders[0].order_id !== null);
          setOrders(ordersArray);
        })
        .catch(error => console.error("Failed to fetch orders:", error));
    };

    fetchOrders();
  }, []);

  // Écoute de l'événement socket "orderUpdated"
  useEffect(() => {
    const API_URL = process.env.REACT_APP_API_URL;
    const socket = io(API_URL);

    socket.on('orderUpdated', ({ orderId, staff_id }) => {
      console.log('Received orderUpdated event:', { orderId, staff_id });
      setOrders(prevOrders => {
        const orderIndex = prevOrders.findIndex(order => order.orderId === String(orderId));
        let updatedOrders = [...prevOrders];
        if (orderIndex !== -1) {
          const targetOrder = prevOrders[orderIndex];
          const processedOrderCreatedAt = targetOrder.orderDetails.createdAt;
          const currentStatus = targetOrder.orderDetails.orders[0].status;

          if (currentStatus === 'pending') {
            console.log('Order status changed from pending to processing for order:', orderId);
            updatedOrders[orderIndex] = {
              ...targetOrder,
              orderDetails: {
                ...targetOrder.orderDetails,
                orders: targetOrder.orderDetails.orders.map(item => ({
                  ...item,
                  status: 'processing',
                })),
              },
            };
            updatedOrders = updatedOrders.map(order => {
              if (
                order.orderDetails.orders[0].status === 'pending' &&
                new Date(order.orderDetails.createdAt) > new Date(processedOrderCreatedAt) &&
                order.orderDetails.pendingBefore > 0
              ) {
                return {
                  ...order,
                  orderDetails: {
                    ...order.orderDetails,
                    pendingBefore: order.orderDetails.pendingBefore - 1,
                  },
                };
              }
              return order;
            });
          } else if (currentStatus === 'processing') {
            console.log('Order status changed from processing to delivered for order:', orderId);
            updatedOrders[orderIndex] = {
              ...targetOrder,
              orderDetails: {
                ...targetOrder.orderDetails,
                orders: targetOrder.orderDetails.orders.map(item => ({
                  ...item,
                  status: 'delivered',
                })),
              },
            };
          }
        } else {
          console.log('Event for external order - decrementing pendingBefore');
          updatedOrders = updatedOrders.map(order => {
            if (
              order.orderDetails.orders[0].status === 'pending' &&
              order.orderDetails.pendingBefore > 0
            ) {
              return {
                ...order,
                orderDetails: {
                  ...order.orderDetails,
                  pendingBefore: order.orderDetails.pendingBefore - 1,
                },
              };
            }
            return order;
          });
        }
        return updatedOrders;
      });
    });

    return () => {
      socket.off('orderUpdated');
      socket.disconnect();
      console.log('Socket disconnected');
    };
  }, []);

  // Séparation des commandes actives et historiques
  const activeOrders = orders
    .filter(order => order.orderDetails.orders[0].status !== 'delivered')
    .sort((a, b) => a.orderDetails.pendingBefore - b.orderDetails.pendingBefore);

  const historyOrders = orders
    .filter(order => order.orderDetails.orders[0].status === 'delivered')
    .sort((a, b) => new Date(b.orderDetails.createdAt) - new Date(a.orderDetails.createdAt));

  return (
    <div>
      <MainNavigation />
      <div className="ordersListContainer">
        {activeOrders.map(({ orderId, orderDetails }) => (
          <div key={orderId} className="orderGroup">
            <ReceiptCard
              orderDetails={orderDetails.orders}
              pendingBefore={orderDetails.pendingBefore}
            />
          </div>
        ))}

        {historyOrders.length > 0 && (
          <div className="historySection">
            <div
              className="historyHeader"
              onClick={() => setShowHistory(prev => !prev)}
            >
              <span className="historyTitle">Order History</span>
              <span className="historyArrow">{showHistory ? '▲' : '▼'}</span>
            </div>
            {showHistory &&
              historyOrders.map(({ orderId, orderDetails }) => (
                <div key={orderId} className="orderGroup historyItem">
                  <ReceiptCard
                    orderDetails={orderDetails.orders}
                    pendingBefore={orderDetails.pendingBefore}
                  />
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ReceiptList;







