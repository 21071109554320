import React, { useState } from 'react';
import { 
  TextField, Button, Table, TableBody, TableCell, 
  TableContainer, TableHead, TableRow, Paper, MenuItem 
} from '@mui/material';
import '../styles/SalesSearch.css'; 
import MainNavigationManager from './MainNavigationManager';

export default function SalesSearch() {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchType, setSearchType] = useState('sales'); // 'sales' ou 'transactions'
  const [results, setResults] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleSearch = async () => {
    try {
      const endpoint = `${apiUrl}/api/search/${searchType}`;
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ searchTerm })
      });
      const data = await response.json();
      setResults(data);
    } catch (error) {
      console.error('Error searching:', error);
    }
  };

  return (
    <div>
      <MainNavigationManager />
      <div className="sales-search-container">
        {/* Sélecteur de type de recherche */}
        <TextField
          className="sales-select-input"
          select
          label="Search Type"
          value={searchType}
          onChange={(e) => setSearchType(e.target.value)}
          margin="normal"
          fullWidth
        >
          <MenuItem value="sales">Sales</MenuItem>
          <MenuItem value="transactions">Transactions</MenuItem>
        </TextField>
        
        {/* Champ de recherche */}
        <TextField
          className="sales-search-input"
          fullWidth
          label="Email or Name"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          margin="normal"
        />
        <Button 
          className="sales-search-button"
          variant="contained" 
          onClick={handleSearch}
          sx={{ mt: 2, mb: 4 }}
        >
          Search
        </Button>

        {/* Affichage du tableau selon le type */}
        {results.length > 0 && (
          <TableContainer component={Paper} className="sales-table-container">
            <Table>
              <TableHead>
                <TableRow>
                  {searchType === 'sales' ? (
                    <>
                      <TableCell>Order ID</TableCell>
                      <TableCell>Product Name</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Quantity</TableCell>
                      <TableCell>Created At</TableCell>
                      <TableCell>Time</TableCell>
                    </>
                  ) : (
                    <>
                      <TableCell>ID</TableCell>
                      <TableCell>Amount</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Created At</TableCell>
                      <TableCell>Time</TableCell>
                    </>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {results.map((item) => (
                  <TableRow key={item.id}>
                    {searchType === 'sales' ? (
                      <>
                        <TableCell>{item.order_id}</TableCell>
                        <TableCell>{item.productName}</TableCell>
                        <TableCell>{item.status}</TableCell>
                        <TableCell>{item.quantity}</TableCell>
                        <TableCell>{new Date(item.createdAt).toLocaleDateString()}</TableCell>
                        <TableCell>{new Date(item.createdAt).toLocaleTimeString()}</TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell>{item.id}</TableCell>
                        <TableCell>{item.amount}</TableCell>
                        <TableCell>{item.type}</TableCell>
                        <TableCell>{new Date(item.createdAt).toLocaleDateString()}</TableCell>
                        <TableCell>{new Date(item.createdAt).toLocaleTimeString()}</TableCell>
                      </>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    </div>
  );
}



